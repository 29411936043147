<template>
<div class="addBuild wrapper" v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="top-header">
                <div class="flex items-center gap-3">
                    <button class="bg-white p-1 w-8 h-8 rounded-xl" @click="$router.go(-1)">
                        <i class="el-icon-arrow-left"></i>
                    </button>
                    <h1>"{{ specification.name_cyr }}" - {{$t('one_building_last_year_added_data')}}</h1>
                </div>
            </div>
    <div class="wp-body">
        <header class="wp-header">
            <div class="flex items-center">
                <div class="title-second-header mb-0 ml-0">{{$t('building_last_year_added_data')}}:</div>
            </div>
        </header>
        <el-collapse v-model="activeNames">
            <el-collapse-item :title="$t('all_datas')" name="1">
                <el-form :model="reconstruction" :rules="rules1" ref="reconstruction" class="Form">
                    <div class="input-section">
                        <el-row :gutter="30">
                            <el-col :md="8">
                                <div class="item">
                                    <p> {{$t('construction_year')}} </p>
                                    <el-form-item prop="construction_year">
                                        <el-date-picker name="construction_year" :disabled="disabled" :placeholder="$t('construction_year')" style="width: 100%" v-model="reconstruction.construction_year" type="year" value-format="yyyy">
                                        </el-date-picker>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :md="8">
                                <div class="item">
                                    <p> {{$t('reconstruction_load_bearing_status')}} </p>
                                    <el-form-item prop="load_bearing_status">
                                        <el-select name="reconstruction_load_bearing_status" :disabled="disabled" v-model="reconstruction.load_bearing_status" class="w-full" :placeholder="$t('reconstruction_load_bearing_status')" clearable>
                                            <el-option v-for="item in dictionary.loadbearingstatus" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :md="8">
                                <div class="item">
                                    <p> {{$t('reconstruction_reconstruction_durability')}} </p>
                                    <el-form-item  prop="reconstruction_durability">
                                        <el-select name="reconstruction_reconstruction_durability" :disabled="disabled" v-model="reconstruction.reconstruction_durability" class="w-full" clearable :placeholder="$t('reconstruction_reconstruction_durability')">
                                            <el-option v-for="item in dictionary.reconstructiondurability" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="input-section">
                        <el-row :gutter="30">
                            <el-col :md="8">
                                <div class="item">
                                    <p>{{$t('reconstruction_water_level_value')}}</p>
                                    <el-row :gutter="20">
                                        <el-col :span="18">
                                            <el-form-item prop="water_level.value">
                                                <el-select name="water_level" :disabled="disabled" v-model="reconstruction.water_level.value" :placeholder="$t('reconstruction_water_level_value')" class="w-full" clearable>
                                                    <el-option v-for="item in 100" :key="item" :label="item" :value="item">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6">
                                            <el-form-item prop="water_level.unit">
                                                <el-select name="water_level_unit" :disabled="disabled" v-model="reconstruction.water_level.unit" class="w-full" clearable>
                                                    <el-option v-for="item in units.unit_1" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                                <div class="item">
                                    <p> {{$t('reconstruction_drowning_moisture_load_carrying')}} </p>
                                    <el-form-item prop="drowning_moisture_load_carrying">
                                        <el-input name="drowning_moisture_load_carrying" :disabled="disabled" v-model="reconstruction.drowning_moisture_load_carrying" clearable :placeholder="$t('reconstruction_drowning_moisture_load_carrying')" />
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :md="8">
                                <div class="item">
                                    <p>{{$t('reconstruction_internal_friction_angle_value')}}</p>
                                    <el-row :gutter="20">
                                        <el-col :span="18">
                                            <el-form-item prop="internal_friction_angle.value">
                                                <el-input name="internal_friction_angle" :disabled="disabled" type="number" v-model="reconstruction.internal_friction_angle.value" :placeholder="$t('reconstruction_internal_friction_angle_value')" clearable />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6">
                                            <el-form-item prop="internal_friction_angle.unit">
                                                <el-select name="internal_friction_angle_unit" :disabled="disabled" v-model="reconstruction.internal_friction_angle.unit" class="w-full" clearable>
                                                    <el-option v-for="item in dictionary.unit" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                                <div class="item">
                                    <p> {{$t('reconstruction_mechanical_properties_soil')}} </p>
                                    <el-form-item prop="mechanical_properties_soil">
                                        <el-input name="mechanical_properties_soil" :disabled="disabled" v-model="reconstruction.mechanical_properties_soil" :placeholder="$t('reconstruction_mechanical_properties_soil')" clearable />
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :md="8">
                                <div class="item">
                                    <p> {{$t('reconstruction_soil_type')}} </p>
                                    <el-form-item prop="soil_type">
                                        <el-select name="soil_type" :disabled="disabled" v-model="reconstruction.soil_type" class="w-full" :placeholder="$t('reconstruction_soil_type')" clearable>
                                            <el-option v-for="item in dictionary.soiltype" :key="item.id" :label="item.name" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="item">
                                    <p> {{$t('foundations_open')}} </p>
                                    <el-form-item prop="foundations_open">
                                        <el-input name="foundations_open" :disabled="disabled" v-model="reconstruction.foundations_open" :placeholder="$t('foundations_open')" clearable />
                                    </el-form-item>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <br />
                    <div class="input-section">
                        <el-row :gutter="30">
                            <el-col :md="16">
                                <br>
                            </el-col>
                            <el-col :md="8">
                                <div class="flex justify-end">
                                    <!-- <el-button class="primary-btn"><i class="el-icon-edit mr-1"></i>Ўзгартириш</el-button> -->
                                    <button v-if="role !== 'admin' && !disabled && role !== 'org_admin'" id="save1" @click="save1()"  class="button dark-blue"><i class="el-icon-document-checked mr-1"></i> {{$t('save')}} </button>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-form>
            </el-collapse-item>
            <el-collapse-item :title="$t('additional_datas')" name="2" :disabled="!isCreated">
                <el-form :model="reconstructionsecondstep" :rules="rules2" ref="reconstructionsecondstep" class="Form">
                    <div class="input-section">
                        <el-row :gutter="30">
                            <el-col :md="8">
                                <div class="item">
                                    <p> {{$t('reconstructionsecondstep_natural_disasters')}} </p>
                                    <el-form-item prop="natural_disasters">
                                        <el-select name="natural_disasters" :disabled="disabled" v-model="reconstructionsecondstep.natural_disasters" class="w-full" :placeholder="$t('please_enter_data')" multiple>
                                            <el-option v-for="item in dictionary.naturaldisasters" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="item" style="overflow: hidden">
                                    <p> {{$t('reconstructionsecondstep_consequences_natural_disasters')}} </p>
                                    <el-form-item prop="consequences_natural_disasters">
                                        <el-select name="consequences_natural_disasters" :disabled="disabled" multiple v-model="reconstructionsecondstep.consequences_natural_disasters" :placeholder="$t('please_enter_data')" class="w-full">
                                            <el-option v-for="item in dictionary.consequencesnaturaldisasters" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>

                                <div class="item" style="overflow: hidden">
                                    <p> {{$t('reconstructionsecondstep_explosion_safety')}} </p>
                                    <el-form-item prop="explosion_safety">
                                        <el-select name="explosion_safety" :disabled="disabled" v-model="reconstructionsecondstep.explosion_safety" :placeholder="$t('please_enter_data')" class="w-full">
                                            <el-option v-for="item in dictionary.explosionsafety" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>

                            </el-col>
                            <el-col :md="8">
                                <div class="item">
                                    <p> {{$t('reconstructionsecondstep_assessed_from_natural_disasters')}} </p>
                                    <el-form-item prop="assessed_from_natural_disasters">
                                        <el-input name="assessed_from_natural_disasters" :disabled="disabled" v-model="reconstructionsecondstep.assessed_from_natural_disasters" :placeholder="$t('please_enter_data')" clearable />
                                    </el-form-item>
                                </div>
                                <div class="item">
                                    <p> {{$t('reconstructionsecondstep_determination_dynamic_indicators')}} </p>
                                    <el-form-item prop="determination_dynamic_indicators">
                                        <el-input name="determination_dynamic_indicators" :disabled="disabled" v-model="reconstructionsecondstep.determination_dynamic_indicators" :placeholder="$t('please_enter_data')" clearable />
                                    </el-form-item>
                                </div>
                                <div class="item">
                                    <el-upload :disabled="disabled" class="upload-demo" ref="upload1" action="#" :before-upload="beforeUpload1" :before-remove="beforeRemove1" multiple :file-list="file1List">
                                        <div class="upload-field flex justufy-between text-left">
                                            <el-button id="reconstruction_drawing" class="primary-btn h-8" size="small"> {{$t('choose_file')}} </el-button>
                                            <p class="ml-5 text-base"> {{$t('reconstruction_drawing')}} </p>
                                        </div>
                                    </el-upload>
                                </div>
                            </el-col>
                            <el-col :md="8">
                                <div class="item">
                                    <p>{{$t('reconstructionsecondstep_perform_control_calculations')}}</p>
                                    <el-form-item prop="perform_control_calculations">
                                        <el-input name="perform_control_calculations" :disabled="disabled" v-model="reconstructionsecondstep.perform_control_calculations" :placeholder="$t('please_enter_data')" clearable />
                                    </el-form-item>
                                </div>
                                <div class="item">
                                    <p> {{$t('reconstructionsecondstep_earthquake_damage_harm')}} </p>
                                    <el-form-item prop="earthquake_damage_harm">
                                        <el-input name="earthquake_damage_harm" :disabled="disabled" v-model="reconstructionsecondstep.earthquake_damage_harm" :placeholder="$t('please_enter_data')" clearable />
                                    </el-form-item>
                                </div>
                                <div class="item">
                                    <p>{{ $t('reconstructionsecondstep_construction_strengthen_recommend')}} </p>
                                    <el-form-item prop="construction_strengthen_recommend">
                                        <el-input name="construction_strengthen_recommend" :disabled="disabled" v-model="reconstructionsecondstep.construction_strengthen_recommend" :placeholder="$t('please_enter_data')" clearable />
                                    </el-form-item>
                                </div>
                                <div class="item">
                                    <p>  {{$t('reconstructionsecondstep_assessment_technical_condition')}} </p>
                                    <el-form-item prop="assessment_technical_condition">
                                        <el-select name="assessment_technical_condition" :disabled="disabled" v-model="reconstructionsecondstep.assessment_technical_condition" class="w-full" :placeholder="$t('please_enter_data')" clearable>
                                            <el-option v-for="item in dictionary.assessmenttechnicalcondition" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="item">
                                    <p class="mTitle"> {{$t('reconstructionsecondstep_finalconclusion')}} </p>
                                    <el-form-item prop="finalconclusion">
                                        <el-select name="reconstructionsecondstep_finalconclusion" :disabled="disabled" v-model="reconstructionsecondstep.finalconclusion" class="final mt-3 w-full" :placeholder="$t('please_enter_data')" clearable>
                                            <el-option v-for="item in dictionary.finalconclusion" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="actions mt-5">
                                    <div class="primary flex justify-end"> 
                                        <button v-if="role !== 'admin' && !disabled" id="save2"  class="button dark-blue" @click="save2()" ><i class="el-icon-document-checked mr-1"></i> {{$t('save')}} </button>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-form>
            </el-collapse-item>
        </el-collapse>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            a:'',
            obj: {},
            loading: false,
            disabled: false,
            file1List: [],
            specification: {},
            reconstruction: {
                specification: null,
                construction_year: null,
                load_bearing_status: null,
                reconstruction_durability: null,
                water_level: {
                    id: null,
                    value: null,
                    unit: 1
                },
                drowning_moisture_load_carrying: "",
                internal_friction_angle: {
                    id: null,
                    value: null,
                    unit: 8,
                },
                mechanical_properties_soil: "",
                soil_type: null,
                foundations_open: ""
            },

            reconstructionsecondstep: {
                natural_disasters: [],
                consequences_natural_disasters: [],
                explosion_safety: null,
                assessed_from_natural_disasters: null,
                determination_dynamic_indicators: null,
                perform_control_calculations: null,
                earthquake_damage_harm: null,
                construction_strengthen_recommend: null,
                assessment_technical_condition: null,
                finalconclusion: null
            },
            rules1: {
                construction_year: [{
                    required: true,
                    message: "Реконструкция қилинган йилини киритинг",
                    trigger: "change"
                }, ],
                water_level: {
                    value: [{
                        required: true,
                        message: "Сизот сувлар сатҳини киритинг",
                        trigger: "change",
                    }, ],
                    unit: [{
                        required: true,
                        message: this.$t('choose_unit'),
                        trigger: "change",
                    }, ],
                },
                drowning_moisture_load_carrying: [{
                    required: true,
                    message: "Чўкувчанлик, намлик, юк кўтариш қобилиятини киритинг",
                    trigger: "change",
                }, ],
                load_bearing_status: [{
                    required: true,
                    message: this.$t('choose_loadbearingstatus'),
                    trigger: "change",
                }, ],
                internal_friction_angle: {
                    value: [{
                        required: true,
                        message: "Ички ишқаланиш бурчагини киритинг",
                        trigger: "change",
                    }, ],
                    unit: [{
                        required: true,
                        message: this.$t('choose_unit'),
                        trigger: "change",
                    }, ],
                },
                mechanical_properties_soil: [{
                    required: true,
                    message: "Грунтнинг механик хоссаларини киритинг",
                    trigger: "change",
                }, ],
                reconstruction_durability: [{
                    required: true,
                    message: "Реконструкция давомида мустаҳкамланганлигини танланг",
                    trigger: "change",
                }, ],
                soil_type: [{
                    required: true,
                    message: "Грунт тоифасини танланг",
                    trigger: "change",
                }, ],
                foundations_open: [{
                    required: true,
                    message: "Пойдеворларни очишни киритинг",
                    trigger: "change",
                }, ],
            },
            rules2: {
                natural_disasters: [{
                    required: true,
                    message: "Содир бўлган табиий офатларни танланг",
                    trigger: "change",
                }, ],
                consequences_natural_disasters: [{
                    required: true,
                    message: "Табиий офатлар оқибатларини танланг",
                    trigger: "change",
                }, ],
                explosion_safety: [{
                    required: true,
                    message: "Портлашдан хавсизликлиги, вентиляциянинг холатини танланг",
                    trigger: "change",
                }, ],
                assessed_from_natural_disasters: [{
                    required: true,
                    message: "Моддий зарар ва одамлар ҳаёт хавфсизлиги баҳоланганлигини киритинг",
                    trigger: "change",
                }, ],
                determination_dynamic_indicators: [{
                    required: true,
                    message: "Динамик кўрсаткичларини аниқлашни киритинг",
                    trigger: "change",
                }, ],
                perform_control_calculations: [{
                    required: true,
                    message: "Назорат ҳисоблашларини амалга оширишни киритинг",
                    trigger: "change",
                }, ],
                earthquake_damage_harm: [{
                    required: true,
                    message: "Эҳтимолий зарарни киритинг",
                    trigger: "change",
                }, ],
                construction_strengthen_recommend: [{
                    required: true,
                    message: "Тавсияларни ишлаб чиқишни киритинг",
                    trigger: "change",
                }, ],
                assessment_technical_condition: [{
                    required: true,
                    message: "Объектнинг техник ҳолатини текширув натижалари бўйича баҳолашни киритинг",
                    trigger: "change",
                }, ],
                status: [{
                    required: true,
                    message: "Якуний хулосани танланг",
                    trigger: "change",
                }, ],
                finalconclusion: [{
                    required: true,
                    message: "Якуний хулосани танланг",
                    trigger: "change",
                }, ],
            },
            activeNames: ["1"],
            textarea1: null,
            isCreated: false,
            value: null,
            value1: null,
            value3: null,
            props: {
                multiple: true
            },
        };
    },
    watch: {
        reconstruction: function (val) {
            this.getFiles();
        },
    },
    computed: {
        role() {
            return this.$store.state.role;
        },
        file1Url() {
            return axios.defaults.baseURL + "dictionary/necessarydrawingsfile/";
        },
        dictionary() {
            return this.$store.state.dictionary;
        },
        units() {
            return this.$store.state.units;
        },
    },
    mounted() {
        this.reconstruction.specification = this.$route.query.id;
        this.__GET();

        axios.get(`/building/specification/update/${this.$route.query.id}/`)
            .then((response) => {
                this.specification = response.data
            })
    },
    methods: {
        role2() {
            let a = '';
            if ('role' in this.$store.state.me) {
                a = this.$store.state.me.role.name;
                if (a == 'admin') {
                    this.disabled = true;
                }
                if (a=='operator' && this.specification.buildingtype!="EXG"){
                    this.disabled = true;

                }
            }
        },
        save1() {
            this.$refs.reconstruction.validate((valid) => {

                if (valid) {
                    this.loading = true;
                    axios({
                            method: !this.isCreated ? "POST" : "PUT",
                            url: !this.isCreated ? "/reconstruction/" : `/reconstruction/${this.reconstruction.id}/`,
                            data: this.reconstruction,
                        })
                        .then((response) => {
                            this.__GET()
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Реконструкция маълумотлари сакланди',
                                timer: 3000
                            })
                            .then((a)=>{
                                this.activeNames="2"
                            })
                            this.isCreated = true;
                        })
                        .catch((error) => {
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('error_save'),
                                timer: 3000
                            })
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            })
        },
        save2() {
            this.$refs.reconstructionsecondstep.validate((valid) => {

                if (valid) {
                    this.loading = true;
                    axios.put(`/reconstructionsecondstep/${this.reconstruction.id}/`, this.reconstructionsecondstep)
                        .then((response) => {
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Реконструкция маълумотлари сакланди',
                                timer: 3000
                            })

                            // generate file
                            axios.post(`/building/specification/${this.$route.query.id}/generate/`)
                            .then((res)=>{
                                this.$router.go(-1)
                            })
                        })
                        .catch((error) => {
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('error_save'),
                                timer: 3000
                            });
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            })
        },
        goBack() {
            window.history.back();
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `The limit is 3, you selected ${
          files.length
        } files this time, add up to ${files.length + fileList.length} totally`
            );
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`Cancel the transfert of ${file.name} ?`);
        },
        panToFirst() {
            this.coords = this.initialCoords;
        },
        clicked(e) {
            this.coords = e.get("coords");
        },
        getFiles() {
            if ("id" in this.reconstruction) {
                axios
                    .get(
                        `/dictionary/necessarydrawingsfile/?necessarydrawingsfile=${this.reconstruction.id}`
                    )
                    .then((response) => {
                        this.file1List = response.data;
                    });
            }
        },
        beforeRemove1(file, fileList) {
            return this.$confirm(`Ушбу файлни учирмокчимисиз ${file.name} ?`).then(
                () => {
                    axios
                        .delete(`/dictionary/necessarydrawingsfile/${file.id}/`)
                        .then((response) => {
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Файл учирилди',
                                timer: 3000
                            });
                        })
                        .catch((error) => {
                            this.$swal.fire({
                                icon: 'error',
                                title: "Файл учиришда хатолик",
                                timer: 3000
                            })
                        });
                }
            );
        },
        __GET() {
            this.loading = true;
            axios
                .get(`/reconstruction/?specification=${this.$route.query.id}`)
                .then((response) => {
                    this.isCreated = !!response.data[0];

                    if (this.isCreated) {
                        this.getFiles();
                        this.reconstruction = response.data[0];
                        this.reconstruction.construction_year = String(
                            this.reconstruction.construction_year
                        );
                    this.role2()
                    this.__GET2();
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        __GET2() {
            axios.get(`/reconstructionsecondstep/${this.reconstruction.id}/`)
                .then((response) => {
                    this.reconstructionsecondstep = response.data;
                })
        },
        beforeUpload1(file) {
            if (this.isCreated) {
                let formData = new FormData();
                formData.append("name", file.name);
                formData.append("file", file);
                formData.append("necessarydrawingsfile", this.reconstruction.id);
                axios.post(this.file1Url, formData).then((response) => {
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('file_secsess_send'),
                        timer: 3000
                    });
                    this.getFiles(1);
                });
            } else {
                this.$swal.fire({
                    icon: 'error',
                    title: "Файлни йуборишдан олдин реконструкцияни сакланг",
                    timer: 3000
                })
            }
        },
    },
};
</script>

<style lang="scss">
.el-collapse-item__header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 37px;
    display: flex;
    align-items: center;
    color: #004787 !important;
    padding: 15px 0;
    border-bottom: none;
}

.el-upload-list__item {
    transition: none;
}

.el-form-item {
    margin-bottom: 0px !important;
}
 

.title-second-header {
    font-size: 18px;
    font-weight: 600;
    color: #345a63 !important;
}

</style>
